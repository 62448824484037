import { parse } from 'url';

import memoize from 'lodash/memoize';

import { createSocketIOCollabProvider } from '@atlaskit/collab-provider/socket-io-provider';
import type { Config as CollabProviderConfig } from '@atlaskit/collab-provider/types';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { getApolloClient } from '@confluence/graphql';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import type { SessionDataType } from '@confluence/session-data';
import { getSessionData } from '@confluence/session-data';
import { CollabEditProviderMeasures } from '@confluence/action-measures';

import { NativeCollabProviderQuery } from './NativeCollabProviderQuery.graphql';
import {
	type NativeCollabProviderQuery as NativeCollabProviderQueryType,
	type NativeCollabProviderQueryVariables,
} from './__types__/NativeCollabProviderQuery';
import { COLLAB_EDIT_PROVIDER_MEASURES } from './CollabEditProviderMeasures';
import type { InitialDraft } from './fetchDraft';
import { getCollabProviderUrl } from './collabProviderUrl';

export const getUser = (
	userId: string,
): Promise<{ avatar: string; name: string; email: string; userId: string }> => {
	return getApolloClient()
		.query<NativeCollabProviderQueryType, NativeCollabProviderQueryVariables>({
			query: NativeCollabProviderQuery,
			variables: {
				accountId: userId,
			},
		})
		.then(({ data }) => {
			const primaryPhoto = (data?.user?.photos || []).find((photo) => photo?.isPrimary);

			return {
				avatar: primaryPhoto ? primaryPhoto.value ?? '' : '',
				name: data?.user?.displayName ?? '',
				email: '', // Is this needed?
				userId,
			};
		});
};

export const resolverOfMemoize = (options: CollabEditorProviderOptions) => {
	const cloudId = options?.cloudId ?? '';
	const contentId = options?.contentId ?? '';
	const contentType = options?.contentType ?? '';
	return `${cloudId}_${contentId}_${contentType}`;
};

export type CollabEditorProviderOptions = {
	contentId: string;
	cloudId: string;
	contentType: string;
	permissionTokenRefresh?: CollabProviderConfig['permissionTokenRefresh'];
	initialDraft?: InitialDraft | null;
	isLivePage?: boolean;
};

export const getCollabEditProvider = memoize((options: CollabEditorProviderOptions) => {
	// start measuring duration for initializing the provider
	CollabEditProviderMeasures.markMeasureStart(
		COLLAB_EDIT_PROVIDER_MEASURES.COLLAB_EDIT_PROVIDER_INIT,
	);
	CollabEditProviderMeasures.markMeasureStart(
		COLLAB_EDIT_PROVIDER_MEASURES.COLLAB_EDIT_PROVIDER_FETCH,
	);
	const { contentId, cloudId, contentType, permissionTokenRefresh, initialDraft, isLivePage } =
		options;
	const url = getCollabProviderUrl();
	const isEmbeddedConfluence = isEmbeddedConfluence_DO_NOT_USE();
	const product = isEmbeddedConfluence ? 'embeddedConfluence' : 'confluence';
	const documentAri = `ari:cloud:confluence:${cloudId}:${contentType}/${contentId}`;
	const subProduct = isEmbeddedConfluence
		? parse(location.search, true).query.parentProduct
		: isLivePage
			? 'live'
			: undefined;

	return getSessionData().then((sessionData: SessionDataType) => {
		CollabEditProviderMeasures.markMeasureEnd(
			COLLAB_EDIT_PROVIDER_MEASURES.COLLAB_EDIT_PROVIDER_FETCH,
		);

		const usePermissionToken =
			isEmbeddedConfluence ||
			sessionData.featureFlagClient.getBooleanValue('confluence.frontend.ncs.perms.token', {
				default: false,
			});

		// https://switcheroo.atlassian.com/changes/confluence/confluence.frontend.ncs.block-view-only
		const blockViewOnly = sessionData.featureFlagClient.getBooleanValue(
			'confluence.frontend.ncs.block-view-only',
			{ default: false },
		);

		// https://switcheroo.atlassian.com/changes/confluence/confluence.frontend.ncs.reconcile-on-recovery
		const reconcileOnRecovery = sessionData.featureFlagClient.getBooleanValue(
			'confluence.frontend.ncs.reconcile-on-recovery',
			{ default: false },
		);

		CollabEditProviderMeasures.markMeasureStart(
			COLLAB_EDIT_PROVIDER_MEASURES.COLLAB_EDIT_PROVIDER_CREATED,
		);

		// DO NOT add any asynchronous calls/behavior to the collab provider creation. This has caused incidents
		// in the past where the provider promise never resolves and editing breaks.
		// Context: https://hello.atlassian.net/wiki/spaces/~589311246/pages/2554442259/Investigate+async+issue+HOT-103180
		return createSocketIOCollabProvider({
			documentAri,
			url,
			getUser,
			getAnalyticsWebClient: getAnalyticsWebClient(),
			...(usePermissionToken ? { permissionTokenRefresh } : {}),
			need404: true,
			productInfo: {
				product,
				subProduct: Array.isArray(subProduct) ? subProduct.join('-') : subProduct,
			},
			featureFlags: {
				blockViewOnly,
				reconcileOnRecovery,
			},
			// either pass the initialDraft that was fetched on hover, or the draftResponse that was fetched on click
			initialDraft: initialDraft || undefined,
			isBufferingEnabled: Boolean(initialDraft), // buffering should only be enabled when the initial draft is present
			__livePage: isLivePage,
		});
	});
}, resolverOfMemoize);
